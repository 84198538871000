<script>
import DatePicker from "vue2-datepicker";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import Swal from "sweetalert2";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import AccountService from "@/services/AccountService";
import ClientService from "@/services/ClientService";
import MovementService from "@/services/MovementService";
import HelperJS from "@/functions/general";
import moment from "moment";
import {VueSignaturePad} from 'vue-signature-pad';

import Select2 from 'v-select2-component';

import {  mapState } from "vuex";

import 'vue2-datepicker/locale/es';
  


const movementService = new MovementService();
const clientService = new ClientService();
const accountService = new AccountService();
const helper = new  HelperJS();

/**
 * Invoice-list component
 */
export default {
  page: {
    title: "Cuentas activas",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader,
    Select2,
    VueSignaturePad,
    DatePicker },
  data() {
    return {
      title: "Cuentas activas",
      value: '',
      userAccounts: [],
      infoPage:{
        method_payments: [
          {
            id:1,
            text: 'Efectivo'
          },
          {
            id:2,
            text: 'Transferencia' 
          },
          {
            id:3,
            text: 'Cheque' 
          },
        ],
        currencys:['MXN', 'USD'],
        view: 'index',
        example: [{id:1, text:'test'}],
        actionType:'add',
        btnActive: true

      },
      items: [
        {
          text: "Inicio",
        },
        {
          text: "Cuentas",
          active: true,
        },
      ],
      invoicelist: [
        
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      prestamistas: [],
      formAccount:{
        name: null,
        start_date: null,
        currency: 'MXN',
        interest: null,
        cut_date: null,
        amount: null,
        latest_retirement: null,
        id: null,
        client_id: null,
      },
      formBalance:{
        id: null,
        account_id: null,
        date: null,
        currency: 'MXN',
        amount: null,
        method_payment: 1,
        type: 1,
        comment: null,
        client_id: null,
        user_id: null,
        image: null,

      },
      fields: [
      {
          key: "client.full_name",
          label: "Nombre prestamista",
          sortable: true,
        },
        {
          key: "start_amount",
          label: "Monto Inicial",
          sortable: true,
        },
        {
          key: "start_date",
          sortable: true,
          label: "Fecha inicial",
        },
        
        {
          key: "currency",
          sortable: true,
          label: "Moneda",
        },
         {
          key: "interest",
          sortable: true,
          label: "Interés Mensual",
        },
        {
          key: "amount",
          sortable: true,
          label: "Saldo",
        },
        {
          key: "latest_retireiment",
          sortable: true,
          label: "Último cobro",
        },
        {
          key: "action",
          label:  "Acción",
        },
       
      ],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
  ...mapState('auth', ['currentUser']),
    rows() {
      return this.invoicelist.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  
  created(){

    this.chargeItems();
  },
  methods: {
    /**
     * Search the table data with search input
     */
    
    formatMoney(value){
      return helper.formatMoney(value);

    },
    formatDate(value, type='short'){
      return helper.formatDate(value, type);

    },
    onchange(option){
      console.log('onchange');    
      this.value = option.value;
    },
    chargeItems(){
        accountService.getList().then(response=>{
          this.invoicelist = response.data.accounts;
        });
          clientService.getList().then(response=>{
          let prestamistas = response.data.clients;
          prestamistas.map(item=> item.text = item.full_name);

        

          this.prestamistas = prestamistas;
        });

    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    routeGo(textRoute){
      this.$router.push({
        path: textRoute,
      });
    },

    addAccount(){
      this.infoPage.actionType = 'add-account';
      this.$bvModal.show('modal-account')
      
    },
    
    addCapital(){
      this.infoPage.actionType = 'add-capital';
      this.$bvModal.show('modal-standard')
      
    },




    editAccount(account){
      console.log('account', account);

      for(let prop in account){
        this.formAccount[prop] = account[prop];
      }
      
      this.infoPage.actionType = 'edit-account';
      console.log('account', this.formAccount);
      this.$bvModal.show('modal-account')
    },
     async formSubmit() {
      //console.log('enviado', this.$v);
      //this.submitted = true;
      /*if(this.$v.$error===false &&  this.$v.$invalid===false){
        console.log('validado');*/
        let formData = {...this.formAccount};
        if( formData.id === null){
          delete formData.id;
          formData.amount = 0;
          formData.start_date = moment().format('YYYY-MM-DD');
          await accountService.create(formData)
          console.log('requestResponse', );
        }else{
          await accountService.update(formData)
        }
        this.clearAccount();
        //this.$bvModal.hide('modal-account')
      
        this.closeModal('modal-account');
        this.chargeItems();
      //}
      
      // stop here if form is invalid
      this.$v.$touch();
    },
    async formSubmitMovement(){
        let formData = {...this.formBalance};
        formData.user_id = this.currentUser.id;
        if( formData.id === null){
          delete formData.id;
          formData.date = moment().format('YYYY-MM-DD');
          await movementService.create(formData)
          console.log('requestResponse');
        }else{
          await movementService.update(formData)
        }
        this.clearMovement();
        this.closeModal('modal-standard');
        this.chargeItems();
      //}
      
      // stop here if form is invalid
      this.$v.$touch();
    },
    closeModal(nameModal){
        this.$bvModal.hide(nameModal)
    },
    clearAccount(){
      this.formAccount = {
        name: null,
        start_date: null,
        currency: 'MXN',
        interest: null,
        cut_date: null,
        amount: null,
        latest_retirement: null,
        id: null,
        client_id: null,
      };
    },
    clearMovement(){

      this.formBalance = {
        id: null,
        account_id: null,
        date: null,
        currency: 'MXN',
        amount: null,
        method_payment: 1,
        type: 1,
        comment: null,
        client_id: null,
        user_id: null,
        image:null,
      };
    },
    checkAccounts(){
      let client_id = this.formBalance.client_id;
      let params = {
        client_id:client_id
      }

        accountService.getList(params).then(response=>{
          let userAccounts = response.data.accounts;
          //console.log(userAccounts, 'userAccounts');
          userAccounts.map(item=> item.text = item.name);
          //console.log(userAccounts, 'text');
          this.userAccounts = userAccounts;
        });
    },


    checkAccountsInfo(){
      
      this.infoPage.btnActive = true;

      if(this.infoPage.actionType==='add-account'){
        let client_id = this.formAccount.client_id;
        let params = {
          client_id:client_id
        }
        accountService.getList(params).then(response=>{
          let userAccounts = response.data.accounts;
          //console.log(userAccounts.length, client_id);
          if(userAccounts.length>1){
            this.infoPage.btnActive = false;
            this.warningForm();
          }
        });
      }
      
    },
    checkCurrency(){

      let account_id = Number(this.formBalance.account_id);
      let accountFinded = this.userAccounts.find(item=> item.id === account_id);

      //console.log('accountFinded', account_id,  this.userAccounts);
      this.formBalance.currency = accountFinded.currency;



    },

    deleteElement(id){
      accountService.checkInfo(id).then(async (response)=>{

        response = response.data;
        if(response.msg==='ok'){
          await accountService.delete(id);
          this.successForm();
          this.chargeItems();
        }
        if(response.msg==='error'){

          this.errorForm(response.errorsResponse);
        }

        console.log('response', response);
        
      })

      
    },

    warningForm(){
      Swal.fire("¡Operación cancelada!", "El prestamista ya tiene el máximo de cuentas permitido", "warning");
    },
    
    successForm(){
      Swal.fire("¡Operación exitosa!", "El registro fué eliminado", "success");
    },
    
    errorForm(errorResponse) {
      let htmlResponse = '';

      errorResponse.forEach(element => {
        htmlResponse = htmlResponse +' '+element+ ' </br>'
        
      });
      Swal.fire({
        title: "¡Operación cancelada!",
        icon: "warning",
        html:htmlResponse,
        focusConfirm: false,
        confirmButtonText: 'Ok',
      });
    },
      undo() {
      this.$refs.signaturePad.clearSignature();
       //const { isEmpty, data } = this.$refs.signaturePad.saveSignature();

      //alert("Open DevTools see the save data.");
     /**console.log(isEmpty);
      console.log(data);*/
    },
    
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
         
    <div class="row">
      <div class="col-md-6">
        <div class="mb-3">
          <a  class="btn btn-success me-2" 
                 @click="addAccount()"
            ><i class="mdi mdi-plus me-2"></i> Añadir cuenta</a
          >
            <a 
                class="btn btn-success"
                @click.prevent="routeGo('/movimientos/create')"
            > Añadir capital / Retirar capital</a
          >
        </div>
      </div>
      <div class="col-md-6">
        <div class="float-end">
          <div class="form-inline mb-3">
            <div
              class="input-daterange input-group"
              data-provide="datepicker"
              data-date-format="dd M, yyyy"
              data-date-autoclose="true"
            >
              <input
                type="text"
                class="form-control text-left"
                placeholder="Desde"
                name="From"
              />
              <input
                type="text"
                class="form-control text-left"
                placeholder="Hasta"
                name="To"
              />
              <div class="input-group-append">
                <button type="button" class="btn btn-primary">
                  <i class="mdi mdi-filter-variant"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <div id="tickets-table_length" class="dataTables_length">
          <label class="d-inline-flex align-items-center fw-normal">
            Mostrar&nbsp;
            <b-form-select
              v-model="perPage"
              size="sm"
              :options="pageOptions"
            ></b-form-select
            >&nbsp;registros
          </label>
        </div>
      </div>
      <!-- Search -->
      <div class="col-sm-12 col-md-6">
        <div id="tickets-table_filter" class="dataTables_filter text-md-end">
          <label class="d-inline-flex align-items-center fw-normal">
            Buscar:
            <b-form-input
              v-model="filter"
              type="search"
              class="form-control form-control-sm ms-2"
            ></b-form-input>
          </label>
        </div>
      </div>
      <!-- End search -->
    </div>
    <!-- Table -->
    <div class="table-responsive mb-0">
      <b-table
        table-class="table table-centered datatable table-card-list"
        thead-tr-class="bg-transparent"
        :items="invoicelist"
        :fields="fields"
        responsive="sm"
        :per-page="perPage"
        :current-page="currentPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template v-slot:cell(check)="data">
          <div class="custom-control custom-checkbox text-center">
            <input
              type="checkbox"
              class="custom-control-input"
              :id="`contacusercheck${data.item.id}`"
            />
            <label
              class="custom-control-label"
              :for="`contacusercheck${data.item.id}`"
            ></label>
          </div>
        </template>
        <template v-slot:cell(id)="data">
          <a href="javascript: void(0);" class="text-dark fw-bold">
            {{ data.item.id }}
          </a>
        </template>
        
        <template v-slot:cell(start_date)="data">
          <label class="custom-control-label">
            {{ data.item.movements && data.item.movements.length?formatDate(data.item.movements[0].date):'' }}
          </label>
        </template>
        <template v-slot:cell(start_amount)="data">
          <label class="custom-control-label">
            {{ data.item.movements && data.item.movements.length?formatMoney(data.item.movements[0].amount):'' }}
          </label>
        </template>

        <template v-slot:cell(amount)="data">
          <label class="custom-control-label">
            {{ formatMoney(data.item.amount) }}
          </label>
        </template>

        <template v-slot:cell(interest)="data">
          <label class="custom-control-label">
            {{ data.item.interest*100 + ' % ' }}
          </label>
        </template>
        <template v-slot:cell(status)="data">
          <div
            class="badge rounded-pill bg-soft-success font-size-12"
            :class="{ 'bg-soft-warning': data.item.status === 'Pending' }"
          >
            {{ data.item.status }}
          </div>
        </template>

        <template v-slot:cell(name)="data">
          <a href="#" class="text-body">{{ data.item.name }}</a>
        </template>
        <template v-slot:cell(download)>
          <div>
            <button class="btn btn-light btn-sm w-xs">
              Pdf
              <i class="uil uil-download-alt ms-2"></i>
            </button>
          </div>
        </template>
        <template v-slot:cell(action)="data">
          <ul class="list-inline mb-0">
            <li class="list-inline-item">
              <a
                 @click="editAccount(data.item)"
                class="px-2 text-primary"
                v-b-tooltip.hover
                title="Editar"
              >
                <i class="uil uil-pen font-size-18"></i>
              </a>
            </li>
             <li class="list-inline-item">
              <a
                 @click="routeGo('/cuentas/details/'+data.item.id)"
                class="px-2 text-primary"
                v-b-tooltip.hover
                title="Ver detalles"
              >
                <i class="uil uil-eye font-size-18"></i>
              </a>
            </li>
            <li class="list-inline-item">
              <a                         
                 @click="deleteElement(data.item.id)"
                class="px-2 text-danger"
                v-b-tooltip.hover
                title="Eliminar"
              >
                <i class="uil uil-trash-alt font-size-18"></i>
              </a>
            </li>
          </ul>
        </template>
      </b-table>
    </div>
    <div class="row">
      <div class="col">
        <div class="dataTables_paginate paging_simple_numbers float-end">
          <ul class="pagination pagination-rounded">
            <!-- pagination -->
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
            ></b-pagination>
          </ul>
        </div>
      </div>
    </div>

     <b-modal
                id="modal-standard"
                title="Añadir capital"
                title-class="font-18" hide-footer
              >
          <div class="card-body">
              <div class="row">
                 <div class="col-12">
                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="userName"
                        >Prestamista</label
                      >
                      <div class="col-md-9">
                        <Select2  @change="checkAccounts()" class="form-control form-custom" v-model="formBalance.client_id" :options="prestamistas" :settings="{ settingOption: value, settingOption: value }" />              
                      
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="password"
                        >Cuenta</label
                      >
                      <div class="col-md-9">
                      <Select2 @change="checkCurrency()" class="form-control form-custom" v-model="formBalance.account_id" :options="userAccounts" :settings="{ settingOption: value, settingOption: value }" />              
                      
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="email"
                        >Tipo</label
                      >
                      <div class="col-md-9">
                         <b-form-radio
                          v-model="formBalance.type"
                          class="mb-3 d-inline-block mrc-3"
                          value="1"
                          plain
                          >Ingreso</b-form-radio>
                        <b-form-radio
                          v-model="formBalance.type"
                          class=" d-inline-block mb-3 "
                          value="0"
                          plain
                          >Retiro</b-form-radio >
                      
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="email"
                        >Fecha</label
                      >
                      <div class="col-md-9">

                          <date-picker
                          v-model="formBalance.date"
                            :first-day-of-week="1"
                            lang="es"
                          ></date-picker>
                      
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="confirmpass"
                        >Monto</label
                      >
                      <div class="col-md-9">
                        <input
                          id="b_amount"
                          type="number"
                          name="b_amount"
                          class="form-control"
                          v-model="formBalance.amount"
                        />
                      </div>
                    </div>
                  </div>
               <div class="col-12">
                
                    
                    <div class="form-group row mb-3">
                    
                      <label class="col-md-3 col-form-label" for="name"
                        >Moneda</label
                      >
                      <div class="col-md-9">
                        <select disabled class="form-control select2" v-model="formBalance.currency">
                          <option :value="currency" v-for="currency in infoPage.currencys" :key="currency" v-text="currency"></option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                    
                      <label class="col-md-3 col-form-label" for="name"
                        >Forma de pago</label
                      >
                      <div class="col-md-9">
                        <select class="form-control select2" v-model="formBalance.method_payment">
                          <option :value="payment.id" v-for="payment in infoPage.method_payments" :key="payment.id" v-text="payment.text"></option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="surname"
                        >Comentarios</label
                      >
                      <div class="col-md-9">
                        <textarea
                          type="text"
                          name="surname"
                          class="form-control"
                          value="Brinkman"
                          v-model="formBalance.comment"
                        ></textarea>
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <div class="col-md-12">
                        <div class="mb-1 mb-0">
                          <label for="productdesc">Firma</label>
                        </div>
                      <div class="container-signature mb-3">
                        <VueSignaturePad width="100%" height="300px" ref="signaturePad" />                   
                      </div>
                      <button   class="btn btn-info ms-1 float-right" @click.prevent="undo">
                          <i class="uil uil-file-alt me-1"></i> Limpiar
                        </button>
                      </div>
                    </div>

                    <div class="box-footer">
                       <div class="col text-end ms-1">
                        <a class="btn btn-danger"  @click.prevent="closeModal('modal-standard')">
                          <i class="uil uil-times me-1"></i> Cancelar
                        </a>
                        <button   class="btn btn-success ms-1" @click.prevent="formSubmitMovement">
                          <i class="uil uil-file-alt me-1"></i> Guardar
                        </button>
                      </div>
                    </div>

                  </div>

                 </div>
     
          </div>       
  </b-modal>


   <b-modal
                id="modal-account"
                title="Añadir cuenta"
                title-class="font-18" hide-footer
              >
          <div class="card-body">
              <div class="row">
                  <div class="col-12">
                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="userName1"
                        >Prestamista</label>
                      <div class="col-md-9">
                    <Select2 @change="checkAccountsInfo()"  :disabled="infoPage.actionType === 'edit-account'" class="form-control form-custom" v-model="formAccount.client_id" :options="prestamistas" :settings="{ settingOption: value, settingOption: value }" />              
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="nameAccount"
                        >Nombre </label
                      >
                      <div class="col-md-9">
                        <input
                          v-model="formAccount.name"
                          id="nameAccount"
                          type="text"
                          name="password"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="AccountInterest"
                        >Interés</label
                      >
                      <div class="col-md-9">
                        <input
                          v-model="formAccount.interest"
                          type="number"
                          name="AccountInterest"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="AccountCutDate"
                        >Dia de corte</label
                      >
                      <div class="col-md-3">
                        <input
                          v-model="formAccount.cut_date"
                          type="number"
                          name="AccountCutDate"
                          class="form-control"
                        />
                      </div>
                       <label class="col-md-3 col-form-label" for="email1"
                        >Moneda</label
                      >
                      <div class="col-md-3">
                        <select class="form-control select2" v-model="formAccount.currency" :disabled="infoPage.actionType === 'edit-account'">
                        <option :value="currency" v-for="currency in infoPage.currencys" :key="currency" v-text="currency"></option>
                        </select>
                      </div>
                    </div>

                    <div class="box-footer">
                       <div class="col text-end ms-1">
                        <a  class="btn btn-danger" @click.prevent="closeModal('modal-account')">
                          <i class="uil uil-times me-1" ></i> Cancelar
                        </a>
                        <button   class="btn btn-success ms-1" @click.prevent="formSubmit" :disabled="!infoPage.btnActive">
                          <i class="uil uil-file-alt me-1"></i> Guardar
                        </button>
                      </div>
                    </div>
                    
                  </div>
                  <!-- end col -->
                </div>
          </div>       
  </b-modal>

  
  </Layout>
</template>
